import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/works.css";

const companies = [
	{
	  name: "Glovo",
	  imageUrl: "./glovo.png", // Make sure this path points to the Glovo logo image in your project
	  roles: [
		{
		  title: "Senior Business Analyst Grocery Global",
		  duration: "Apr 2024 - Present",
		  location: "Barcelona",
		  details: (
            <>
              Developed a tool to manage stock and pricing integration flows between Glovo Quick Commerce and platform partners, enabling the deprecation of the third-party tool, Nexla.
              <ul>
                <li>Resulted in annual savings of €300k by replacing Nexla with an in-house solution.</li>
                <li>Implemented a graphical interface integrated with Google Sheets, allowing non-programmers to interact with the tool.</li>
              </ul>
            </>
		  ),
		},
		{
		  title: "Business Analyst - Tech Solutions",
		  duration: "Sep 2023 - Apr 2024",
		  location: "Barcelona",
		  details: (
			<>
			  Developed an ETL tool to significantly enhance integration observability and streamline the debugging process.
			  <ul>
				<li>Consolidated relevant data in a single location using Looker, which dramatically reduced the time required to debug issues reported by Account Managers or Partners.</li>
				<li>Developed an alert system to notify in advance of any interruptions or drops in data flow, leading to proactive issue resolution.</li>
				<li><em>Improved Issue Detection:</em> Enabled the proactive detection and resolution of over 50% of integration-related issues.</li>
			  </ul>
			</>
		  ),
  
		},
		{
		  title: "Data Integration Manager",
		  duration: "Aug 2022 - Sep 2023", // Specify the actual dates
		  location: "Barcelona",
		  details: (
			<>
			  Developed an ETL tool to significantly enhance integration observability and streamline the debugging process.
			  <ul>
				<li>Consolidated relevant data in a single location using Looker, which dramatically reduced the time required to debug issues reported by Account Managers or Partners.</li>
				<li>Developed an alert system to notify in advance of any interruptions or drops in data flow, leading to proactive issue resolution.</li>
				<li><em>Improved Issue Detection:</em> Enabled the proactive detection and resolution of over 50% of integration-related issues.</li>
			  </ul>
			</>
		  ),
  
		},
		{
		  title: "Operations Manager - Logistics",
		  duration: "Jan 2022 - Aug 2022", 
		  location: "Lombardia - Piemonte Area",
		  details: "Ownership of Last Mile project, Operational Strategy."
		},
	  ],
	},
	{
	  name: "Lidl Italia",
	  imageUrl: "./lidl.png", 
	  roles: [
		{
		  title: "Retail Operations Area Manager",
		  duration: "Jan 2021 - Jan 2022",
		  location: "Milano",
		  details: (
			<>
			  Managed clusters of 4-5 stores, overseeing all aspects related to:
			  <ul>
				<li><strong>Logistic Flow:</strong> Ensuring product availability</li>
				<li><strong>People Management:</strong> Directly supervising store managers</li>
				<li><strong>Customer Experience:</strong> Overseeing checkout operations and store cleanliness</li>
				<li><strong>Compliance:</strong> Ensuring food quality, adherence to company standards, and product positioning</li>
			  </ul>
			</>
		  ),
		},
	  ],
	},
	{
	  name: "Amazon Logistics",
	  imageUrl: "./amazon.png", 
	  roles: [
		{
		  title: "Shift Operations Manager",
		  duration: "Jan 2020 - Jan 2021",
		  location: "Milano",
		  details: (
			<>
			  Held full ownership over the logistics cycle from induction to sortation and dispatching with a customer-centric focus:
			  <ul>
				<li>Organized logistics setup, operations supervisors, and associates based on volume forecasts</li>
				<li>Managed route generation to comply with volume capacities of vans and 3PL contracts</li>
				<li>Enforced strict COVID-19 proxemics rules to ensure warehouse operations continued and goods were delivered to customers despite the pandemic</li>
			  </ul>
			</>
		  ),
		},
		{
		  title: "perations Supervisor",
		  duration: "Aug 2019 - Jan 2020",
		  location: "Milano",
		  details: (
			<>
			  Responsible for overseeing warehouse operations, including induction, sortation, and dispatching:
			  <ul>
				<li>Direct management of sortation associates</li>
				<li>Conducted process training for new associates</li>
				<li>Acted as the Point of Contact (POC) for the integration of the automatic sortation machine</li>
			  </ul>
			</>
		  ),
		},
		
	  ],
	},
	// Add more companies and roles if needed
  ];
  
  const WorkItem = ({ company, roles, imageUrl }) => (
	<div className="work-item">
	  <img src={imageUrl} alt={company} className="work-image" />
	  <div className="work-details">
		<div className="work-company">{company}</div>
		{roles.map((role, index) => (
		  <div key={index} className="work-role">
			<div className="role-title">{role.title}</div>
			<div className="role-duration">{role.duration}</div>
			<div className="role-location">{role.location}</div>
			<div className="role-details">{role.details}</div> {/* Added details */}
		  </div>
		))}
	  </div>
	</div>
);
  
  const Works = () => {
    return (
      <div className="works-container">
        <div className="works-header">
          <FontAwesomeIcon icon={faBriefcase} size="lg" />
          <h2>Work</h2>
        </div>
        {companies.map((item, index) => (
          <WorkItem key={index} company={item.name} roles={item.roles} imageUrl={item.imageUrl} />
        ))}
      </div>
    );
  };
  
  export default Works;