const INFO = {
	main: {
		title: "North West Wind",
		name: "Michele Maestrale",
		email: "michele.maestrale90@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		// twitter: "https://twitter.com/",
		github: "https://github.com/michele1990",
		linkedin: "https://www.linkedin.com/in/michele-maestrale-3250b681/",
		instagram: "https://www.instagram.com/michelemaestrale/?next=%2F",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "North West Wind",
		description: "North West Wind is my personal website showcasing my journey in software development and data engineering. Every part of this site is manually built using AWS, from DNS to article editor, backend with Lambda, and DynamoDB for storing articles.",
		description_add: "The admin panel enables managing articles through a REST API with AWS API Gateway and Lambda. I'm building an analytics system using AWS Athena, and working on features like user login, article likes, comments, and a search function. This site is a hands-on exercise in mastering the tools that power the web."
	  },
	  

	about: {
		title: "Michele Maestrale",
		description:
			"<h1>Data Integration & Operations Specialist | Technology & Process Optimization</h1>	<p>I am a seasoned Data Integration Specialist with extensive experience in developing solutions that drive operational efficiency and cost savings. I’ve successfully led cross-functional projects, focusing on user-centric, data-driven approaches that streamline processes and enhance observability across technical infrastructures. Currently at Glovo, my work includes designing in-house tools that resulted in €300k annual savings.</p>",
	},
	

	articles: {
		title: "Hey There, Welcome to My Blog!",
		description:
			"My projects have been massively boosted by Chat GPT and Github Copilot, sending my learning path and 'time to market' into hyperdrive thanks to generative AI. But here's the deal: my articles zone in on what's still a tough nut for gen AI to crack – the irreplaceable human touch. You'll get a sneak peek into the prompts I use, those teary-eyed manual configurations on cloud platforms like AWS, and how embracing these challenges can ease the life of fellow developers.",
	},

	projects: [
		{
			title: "Full stack webapp template",
			description:
				"Docker-based project with Flask backend, React frontend, Postgres database, and Nginx API Gateway, production-ready web app template, developed with the purpose of acquiring a deep knowledge of the entire stack necessary to put a web app into production from all perspectives.",
			logo: "../projects_logo/app.png",
			linkText: "View Project",
			link: "https://github.com/michele1990/webapp-base/tree/server-test-http",
		},
		{
			title: "Django - Social Network",
			description:
				"Exploring the construction of a Django-based social network, covering admin panel setup, user authentication, Google login, PayPal integration, and chat functionality.",
			logo: "../projects_logo/django.png",
			linkText: "View Project",
			link: "https://www.northwestwind.org/article/11f2db35-44fb-4ae1-864d-5e6dca75990a",
		},

		{
			title: "Django - OpenAI - Paypal Integration",
			description:
				"Exploring Django integration with OpenAI for personalized content and PayPal for secure donations, enhancing user experience with third-party services.",
		   logo: "../projects_logo/openai.png",
			linkText: "View Project",
			link: "https://www.northwestwind.org/article/d3eeb29b-bdad-4a1e-9971-fb80f1a35edc",
		},
		{
			title: "Geospatial Data Visualization and Machine Learning in Python",
			description:
				"Unveiling the Power of Spatial Data Visualization and Machine Learning with Python",
			logo: "../projects_logo/geo.png",
			linkText: "View Project",
			link: "https://www.northwestwind.org/article/345deb65-31af-4b03-9a03-ed173262f31c",
		},

		{
			title: "ETL - Geo-Data visualization (WIP)",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "../projects_logo/etl.png",
			linkText: "View Project",
			link: "https://github.com/michele1990",
		},

	],
};

export default INFO;
